<!--
File: ConditionEditFrom.vue
Description: form for adding/editing a single Condition data (IRI, rutting, etc.).
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion"
              :initial_value="selectedRegion" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad" :initial_value="selectedRoad"
              :region_id="selectedRegion" />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-30">
            <SectionsDropdown
              :class="[{ 'md-valid': !errors.has('fk_section') }, { 'md-error': errors.has('fk_section') }]"
              :label="$t('label.select_section')" v-model="fk_section" data-vv-name="fk_section"
              v-validate="modelValidations.fk_section" :initial_value="condition.fk_section" :region_id="selectedRegion"
              :road_id="selectedRoad" required :is_required="true" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field
              :class="[{ 'md-valid': !errors.has('start_distance') }, { 'md-error': errors.has('start_distance') }]">
              <label>{{ $t('road_network.start_distance') }}</label>
              <md-input v-model="condition.start_distance" type="number" step="100" data-vv-name="start_distance"
                v-validate="modelValidations.start_distance" required />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field
              :class="[{ 'md-valid': !errors.has('end_distance') }, { 'md-error': errors.has('end_distance') }]">
              <label>{{ $t('road_network.end_distance') }}</label>
              <md-input v-model="condition.end_distance" type="number" step='100' data-vv-name="end_distance"
                v-validate="modelValidations.end_distance" required />
            </md-field>
          </div>
        </div>
        <md-divider />

        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[{ 'md-valid': !errors.has('survey_date') }, { 'md-error': errors.has('survey_date') }]">
              <label>{{ $t('condition.survey_date') }}</label>
              <md-input v-model="condition.survey_date" type="date" data-vv-name="survey_date"
                v-validate="modelValidations.survey_date" required />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[{ 'md-valid': !errors.has('fk_interval') }, { 'md-error': errors.has('fk_interval') }]">
              <label>{{ $t('condition.fk_interval') }}</label>
              <md-input v-model="condition.fk_interval" type="number" data-vv-name="fk_interval" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[{ 'md-valid': !errors.has('direction') }, { 'md-error': errors.has('direction') }]">
              <label>{{ $t('inventory.direction') }}</label>
              <md-input v-model="condition.direction" type="number" data-vv-name="direction" />
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput :class="[{ 'md-valid': !errors.has('iri') }, { 'md-error': errors.has('iri') }]"
              :label="$t('condition.iri')" v-model="condition.iri" data-vv-name="iri" v-validate="modelValidations.iri"
              :min="modelValidations.iri.min_value" :step="0.1" required />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput :class="[{ 'md-valid': !errors.has('rut_left') }, { 'md-error': errors.has('rut_left') }]"
              :label="$t('condition.rut_left')" v-model="condition.rut_left" data-vv-name="rut_left"
              v-validate="modelValidations.rut_left" :min="modelValidations.rut_left.min_value" :step="0.1" required />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput :class="[{ 'md-valid': !errors.has('rut_right') }, { 'md-error': errors.has('rut_right') }]"
              :label="$t('condition.rut_right')" v-model="condition.rut_right" data-vv-name="rut_right"
              v-validate="modelValidations.rut_right" :min="modelValidations.rut_right.min_value" :step="0.1"
              required />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput :class="[{ 'md-valid': !errors.has('cracking_m2') }, { 'md-error': errors.has('cracking_m2') }]"
              :label="$t('condition.cracking_m2')" v-model="condition.cracking_m2" data-vv-name="cracking_m2"
              v-validate="modelValidations.cracking_m2" :min="modelValidations.cracking_m2.min_value" :step="0.1" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field :class="[{ 'md-valid': !errors.has('potholes_no') }, { 'md-error': errors.has('potholes_no') }]">
              <label>{{ $t('condition.potholes_no') }}</label>
              <md-input v-model="condition.potholes_no" type="number" data-vv-name="potholes_no"
                v-validate="modelValidations.potholes_no" :min="modelValidations.potholes_no.min_value" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput :class="[{ 'md-valid': !errors.has('repairs_m2') }, { 'md-error': errors.has('repairs_m2') }]"
              :label="$t('condition.repairs_m2')" v-model="condition.repairs_m2" data-vv-name="repairs_m2"
              v-validate="modelValidations.repairs_m2" :min="modelValidations.repairs_m2.min_value" :step="0.1" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <FloatInput
              :class="[{ 'md-valid': !errors.has('edge_break_m2') }, { 'md-error': errors.has('edge_break_m2') }]"
              :label="$t('condition.edge_break_m2')" v-model="condition.edge_break_m2" data-vv-name="edge_break_m2"
              v-validate="modelValidations.edge_break_m2" :min="modelValidations.edge_break_m2.min_value" :step="0.1" />
          </div>
        </div>
        <md-divider></md-divider>

        <span v-if="saveBtnDisabled" class="md-error">
          {{ $t('messages.errors_count', { count: errors.count() }) }}
        </span>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import Swal from 'sweetalert2'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'
import FloatInput from '../Components/FloatInput.vue'

import { onClose } from '@/mixins/onCloseMixin'

export default {
  name: 'condition-edit-form',
  props: {
    oper: String
  },

  components: {
    RegionsDropdown,
    RoadsDropdown,
    SectionsDropdown,
    FloatInput
  },

  data() {
    return {
      cond_id: null,
      selectedRegion: null,
      selectedRoad: null,
      section_desc: null,
      fk_section: null,
      condition: {},

      modelValidations: {
        fk_section: { required: true, numeric: true, min_value: 1 },
        start_distance: { required: true, numeric: true },
        end_distance: { required: true, numeric: true },
        survey_date: { required: true },
        iri: { required: true, decimal: true, min_value: 0.1, max_value: 100 },
        rut_left: { required: true, decimal: true, min_value: 0.1, max_value: 100 },
        rut_right: { required: true, decimal: true, min_value: 0.1, max_value: 100 },
        cracking_m2: { required: false, decimal: true, min_value: 0 },
        potholes_no: { required: false, numeric: true, min_value: 0 },
        repairs_m2: { required: false, decimal: true, min_value: 0 },
        edge_break_m2: { required: false, decimal: true, min_value: 0 },
      }

    }
  },

  mounted() {
    const { cond_id = null } = this.$route.params
    this.cond_id = cond_id
    if (this.oper === 'upd' && this.cond_id) {
      this.$store.dispatch('LOAD_CONDITION_BY_ID', this.cond_id).then((res) => {
        this.condition = Array.isArray(res) ? res[0] : res
        this.fk_section = this.condition.fk_section
        this.condition.survey_date= this.condition.survey_date.slice(0, 10)

        this.$store.dispatch('GET_REGION_ROAD_FROM_SECTION', this.condition.fk_section).then(
          (res) => {
            this.selectedRegion = res.fk_region
            this.selectedRoad = res.fk_road
          },
          (err) => {
            this.selectedRegion = 0
            this.selectedRoad = 0
          }
        )
      })
    }
    this.$nextTick(() => {
      this.$validator.validateAll()
    })
  },

  methods: {
    onClose,
    async validate() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return

      const alert = {
        icon: isValid ? 'success' : 'error',
        text: `${this.$t('route.condition_data')}${this.$t(`messages.cond_was_${isValid ? '' : 'not'}saved`)}`,
        footer: ''
      };

      this.condition.fk_section = this.fk_section
      // *** delete the rows below after Andrey resolves it in the back-end or move to the api side
      this.condition.start_distance = Number(this.condition.start_distance)
      this.condition.end_distance = Number(this.condition.end_distance)
      this.condition.iri = Number(this.condition.iri)
      this.condition.rut_left = Number(this.condition.rut_left)
      this.condition.rut_right = Number(this.condition.rut_right)
      this.condition.cracking_m2 = Number(this.condition.cracking_m2)
      this.condition.potholes_no = Number(this.condition.potholes_no)
      this.condition.repairs_m2 = Number(this.condition.repairs_m2)
      this.condition.edge_break_m2 = Number(this.condition.edge_break_m2)
      // *** end delete

      const payload = this.oper === 'add' ? this.condition : { id: this.cond_id, condition: this.condition }
      if (this.oper !== 'add') {
        delete payload.condition.data_id
      }

      const action = `${this.oper.toUpperCase()}_CONDITION`;
      try {
        await this.$store.dispatch(action, payload);
        this.saved_successfully = true;
      } catch (err) {
        alert.footer = err; // Вставляем ошибку в футер
      }

      await Swal.fire(alert);
      this.$nextTick(() => {
        this.$validator.reset()
      })
      this.onClose();
    }
  },
  watch: {
  },
  computed: {
    saveBtnDisabled() {
      return this.errors.count() > 0
    }
  }
}
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>